import "core-js/modules/es.array.push.js";
import { reactive, toRefs, computed, nextTick, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import httpApi from '@/api/httpApi';
import day from "dayjs";
import { hasAuth } from '@/utils/auth';
import { useMessage } from '@/hooks/web/useMessage';
import { useStore } from "vuex";
export default {
  name: "Headers",
  setup() {
    const message = useMessage();
    const store = useStore();
    const {
      state,
      commit
    } = useStore();
    const logoImg = computed(() => state.appSettings.logoImg);
    const appTitle = computed(() => state.appSettings.appTitle);
    const refData = reactive({
      date: day().format("YYYY-MM-DD"),
      time: null,
      second: day().format("HH:mm:ss"),
      d: `星期${["日", "一", "二", "三", "四", "五", "六"][day().format("d")]}`,
      rootMenus: []
    });
    const time = computed(() => day().format("HH:mm:ss"));
    const platformName = computed(() => store.state.webInfoData.platformName);
    const router = useRouter();
    nextTick(() => {
      setInterval(() => {
        refData.second = day().format("HH:mm:ss");
      });
    });
    onUnmounted(() => {
      clearInterval(refData.time);
    });
    //跳转
    const handleLink = url => {
      router.push(url);
    };
    //进入首页
    const handleLinkHome = () => {
      if (refData.rootMenus.length === 1) {
        message.success('当前账号权限只限首页');
      } else {
        const onoff = refData.rootMenus[1].hasOwnProperty('children');
        router.push(onoff ? refData.rootMenus[1].children[0].index : refData.rootMenus[1].index);
      }
    };
    //获取菜单栏数据
    const getNavList = async () => {
      const {
        data
      } = await httpApi.treeResource();
      commit('setTreeResource', data);
      refData.rootMenus = resoveMenus(router.getRoutes().filter(route => {
        var _route$components;
        return ((_route$components = route.components) === null || _route$components === void 0 || (_route$components = _route$components.default) === null || _route$components === void 0 ? void 0 : _route$components.name) === 'AppLayout';
      })).filter(p => hasAuth(p.permCode));
    };
    function resoveMenus(routes) {
      return routes.map(route => {
        var _route$meta, _route$meta2;
        const mergePathIndex = path => {
          return '/' + (path + '/index').split('/').filter(p => p).join('/');
        };
        const isItem = !route.children || route.children.length === 1 && route.children[0].path === mergePathIndex(route.path);
        const menu = {
          index: route.path,
          title: (_route$meta = route.meta) === null || _route$meta === void 0 ? void 0 : _route$meta.title,
          icon: (_route$meta2 = route.meta) === null || _route$meta2 === void 0 ? void 0 : _route$meta2.icon,
          permCode: route === null || route === void 0 ? void 0 : route.meta.permCode,
          isItem
        };
        if (!isItem) {
          menu.children = resoveMenus(route.children);
        }
        return menu;
      });
    }
    ;
    getNavList();
    return {
      ...toRefs(refData),
      handleLink,
      platformName,
      appTitle,
      time,
      handleLinkHome
    };
  }
};