import { reactive, toRefs, provide, onUnmounted } from "vue";
import Headers from "./components/Header.vue";
import left from "./components/Left.vue";
import right from "./components/Right.vue";
import content from "./components/Content.vue";
import API from "@/api/httpApi";
import APIAuth from "@/api/authority/index";
import { useStore } from 'vuex';
export default {
  name: "newhome",
  components: {
    Headers,
    right,
    content,
    left
  },
  setup() {
    const refData = reactive({
      rightRefs: null,
      leftRefs: null,
      data: {
        deptNum: 0,
        cardNum: 0,
        onlineNum: 0,
        alarmNum: 0,
        activationRate: 0,
        activationNum: 0,
        attDeviceNum: 0,
        monthActiveNum: 0,
        monthCardNum: 0,
        monthExpireNum: 0
      },
      temperatureStatisticalVO: {
        total: 0,
        abnormalNum: 0,
        normalNum: 0,
        studentTotal: 0
      },
      attendanceStatisticalVO: [],
      schoolRankList: [],
      studentTypeList: [],
      schoolType: [{
        value: 1,
        label: '小学'
      }, {
        value: 2,
        label: '初中'
      }, {
        value: 3,
        label: '高中'
      }, {
        value: 4,
        label: '六年一贯制'
      }, {
        value: 5,
        label: '九年一贯制'
      }, {
        value: 6,
        label: '十二年一贯制'
      }, {
        value: 7,
        label: '其他'
      }],
      homeIconList: [],
      time: null
    });
    //获取位置信息
    const {
      state,
      commit
    } = useStore();
    const getWebInfo = async () => {
      const {
        data,
        code,
        msg
      } = await APIAuth.getLoginConf();
      if (code == 0) {
        commit('setAppSettings', {
          logoImg: data.iconUrl,
          appTitle: data.platformName,
          id: data.id,
          deptId: data.deptId,
          tenantId: data.tenantId,
          homeIconList: data.homeIconList ? JSON.parse(data === null || data === void 0 ? void 0 : data.homeIconList) : []
        });
      }
    };
    getWebInfo();
    //获取数据
    const getData = async () => {
      var _refData$rightRefs, _refData$leftRefs;
      const data = await Promise.all([API.newHomeStatistical(), API.selectHomeRankAndType()]);
      const onoff = data.some(item => item.code != 0);
      if (!onoff) {
        var _data$1$data, _data$1$data2;
        // refData.temperatureStatisticalVO = data[0].data.temperatureStatisticalVO;
        //refData.homeIconList = data[0].data.homeIconList?JSON.parse(data[0].data.homeIconList):[];
        //refData.attendanceStatisticalVO = data[0].data.attendanceStatisticalVO.map(item=>({
        //   ...item,
        //   echartData:[
        //     {name:`异常数`,value:item.abnormalNum},
        //     {name:`正常数`,value:item.normalNum}
        //   ]
        // }));
        refData.data = data[0].data;
        refData.schoolRankList = (_data$1$data = data[1].data) === null || _data$1$data === void 0 ? void 0 : _data$1$data.holdRankList;
        refData.studentTypeList = (_data$1$data2 = data[1].data) === null || _data$1$data2 === void 0 ? void 0 : _data$1$data2.deviceTypeList;
      }
      const studentTypeList = refData.studentTypeList.map(item => ({
        name: item.deviceTypeName,
        value: item.deviceTypeNum,
        num: item.deviceTypeNum
      }));
      (_refData$rightRefs = refData.rightRefs) === null || _refData$rightRefs === void 0 || _refData$rightRefs.initEcharts(studentTypeList, refData.schoolRankList, refData.data);
      (_refData$leftRefs = refData.leftRefs) === null || _refData$leftRefs === void 0 || _refData$leftRefs.initEcharts();
      /**
       * 轮询首页数据  60S一次
       */
      refData.time = setTimeout(() => {
        getData();
      }, 1000 * 60);
    };
    getData();
    //传参给子代组件
    const handleProvide = () => {
      provide('rootProvide', refData);
    };
    handleProvide();
    //销毁页面
    onUnmounted(() => {
      clearTimeout(refData.time);
    });
    return {
      ...toRefs(refData)
    };
  }
};