import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-59975f4d"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "home"
};
const _hoisted_2 = {
  class: "home_content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Headers = _resolveComponent("Headers");
  const _component_left = _resolveComponent("left");
  const _component_content = _resolveComponent("content");
  const _component_right = _resolveComponent("right");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_Headers), _createElementVNode("div", _hoisted_2, [_createVNode(_component_left, {
    ref: "leftRefs",
    data: _ctx.data,
    temperatureStatisticalVO: _ctx.temperatureStatisticalVO,
    homeIconList: _ctx.homeIconList
  }, null, 8, ["data", "temperatureStatisticalVO", "homeIconList"]), _createVNode(_component_content, {
    data: _ctx.data
  }, null, 8, ["data"]), _createVNode(_component_right, {
    ref: "rightRefs"
  }, null, 512)])]);
}