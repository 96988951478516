import { createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-0e06d030"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "Echart"
};
const _hoisted_2 = {
  style: {
    "width": "100%",
    "height": "100%"
  },
  ref: "echartRefs"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_loading = _resolveDirective("loading");
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, null, 512)])), [[_directive_loading, _ctx.loading]]);
}