import { defineComponent, reactive, toRefs, onMounted, markRaw } from "vue";
import * as echarts from "echarts";
import 'echarts-liquidfill';
import sleep from "@/utils/sleep";
export default defineComponent({
  name: "Watechart",
  props: {
    data: {
      type: Number,
      default: 0.4
    }
  },
  setup(props) {
    const refData = reactive({
      echartRefs: null,
      myChart: null,
      loading: false
    });
    //设置参数
    const handleOptions = () => {
      refData.myChart = markRaw(echarts.init(refData.echartRefs));
      let option = {
        // 图表主标题
        title: false,
        // 提示框组件
        tooltip: true,
        series: [{
          type: 'liquidFill',
          radius: '100%',
          center: ['50%', '50%'],
          // 水填充图的形状 circle 默认圆形  rect 圆角矩形  triangle 三角形  
          // diamond 菱形  pin 水滴状 arrow 箭头状  还可以是svg的path
          shape: 'circle',
          phase: 0,
          direction: 'right',
          outline: {
            show: true,
            borderDistance: 0,
            itemStyle: {
              opacity: 1,
              borderWidth: 10,
              shadowBlur: 3,
              shadowColor: "#042061",
              borderColor: "#042061" // 边框颜色
            }
          },
          backgroundStyle: {
            color: "#122658",
            opacity: 0.5
          },
          // itemStyle:{
          //   color:'#08ca99',
          // },
          // 图形的高亮样式
          emphasis: {
            itemStyle: {
              opacity: 0.8 // 鼠标经过波浪颜色的透明度
            }
          },
          // 图形上的文本标签
          label: {
            normal: {
              formatter: (props.data * 100).toFixed(1) + '%',
              show: true,
              textStyle: {
                color: '#ffffff',
                fontSize: '22',
                fontWeight: '100'
              }
            }
          },
          data: [{
            value: props.data,
            itemStyle: {
              color: "#072CC2"
            }
          }, {
            value: props.data + 0.111,
            itemStyle: {
              color: "#247CFF"
            }
          }]
        }]
      };
      option && refData.myChart.setOption(option);
      refData.loading = false;
      // 处理点击事件并且跳转到相应的百度搜索页面
    };
    //自适应大小
    const handleResizeChart = () => {
      refData.myChart && refData.myChart.resize();
    };
    //初始化
    const init = async () => {
      await sleep(2000);
      handleOptions();
      window.addEventListener("resize", handleResizeChart);
    };
    onMounted(() => {
      init();
    });
    return {
      ...toRefs(refData),
      init
    };
  }
});