import { reactive, toRefs } from "vue";
import { ElCarousel, ElCarouselItem, ElProgress } from "element-plus";
import PieEchart from "./PieEchart.vue";
import Watechart from "./Watechart.vue";
export default {
  name: 'Right',
  components: {
    ElCarousel,
    ElCarouselItem,
    PieEchart,
    Watechart,
    ElProgress
  },
  setup() {
    const refData = reactive({
      card1Ref: null,
      card2Ref: null,
      schoolRankList: [],
      data: {
        cardNum: 0,
        activationNum: 0,
        alarmNum: 0,
        alarmDealNum: 0
      }
    });
    const initEcharts = (data, schoolRankList, total) => {
      refData.schoolRankList = schoolRankList;
      refData.data = total;
      refData.card2Ref.init(data);
    };
    return {
      ...toRefs(refData),
      initEcharts
    };
  }
};